import React, { Component } from 'react';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import ThankYou from './components/ThankYou';

import './index.css';

export class RequestADemoConfirmationPage extends Component {
  static propTypes = {};

  render() {
    return (
      <App displayGettingStartedPanel={false}>
        <div className="component request-a-demo-confirmation-page">
          <Helmet title="Demo – Hotel Reservation System & Channel Manager – ThinkReservations">
            <meta
              name="description"
              content="Experience how ThinkReservations can save you time and increase your revenue. Contact us for a demo today!"
            />
          </Helmet>

          <ThankYou />
        </div>
      </App>
    );
  }
}

export default RequestADemoConfirmationPage;
