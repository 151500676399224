import React, { Component } from 'react';

import './index.css';

export class ThankYou extends Component {
  static propTypes = {};

  render() {
    return (
      <div className="component thank-you">
        <div className="thank-you-title">Thank you!</div>
        <div className="thank-you-subtitle">
          <strong>We look forward to speaking with you soon.</strong>
        </div>
        <div className="thank-you-description">
          Check your inbox to schedule your personalized demo!
          <br />
          <br />
          <i>
            Look for an email from sales@thinkreservations.com. Don't see it?
            Check your spam folder.
          </i>
        </div>
      </div>
    );
  }
}

export default ThankYou;
